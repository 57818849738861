.prayerContainer {
  margin: 0 30px 60px;
  padding: 20px;
  border-radius: 30px;
  box-shadow: -5px -5px 10px white, 5px 5px 14px rgb(0 0 0 / 15%);
}
.iconNameDate {
  display: flex;
  align-items: center;
  line-height: 1.5em;
}
.letterIcon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2em;
  background: teal;
  margin-right: 10px;
  position: relative;
}
.letterIcon:after {
  width: 70px;
  height: 70px;
  display: block;
  content: "";
  left: 0;
  position: absolute;
  border-radius: 50%;
  background-image: linear-gradient(
    -45deg,
    rgb(0 0 0 / 7%),
    rgb(255 255 255 / 25%)
  );
}
.name {
  margin: 0;
}
.contactInfo {
  margin: 0;
  text-transform: uppercase;
  color: gray;
  font-size: 0.75em;
}
.prayerText {
  display: block;
  margin: 15px 0;
  font-weight: 300;
  padding: 20px 0px;
}
.divider {
  font-size: 1.2em;
  color: #222;
  margin: 0px 6px;
}
.pillBadges div {
  font-size: 0.8em;
  background: #fdfdfd;
  padding: 3px 9px;
  display: inline-block;
  margin: 0px 10px 0px 15px;
  color: rgb(97, 97, 97);
  border-radius: 30px;
  text-shadow: 0px 0px 4px white;
  font-weight: 300;
  box-shadow: -5px -5px 7px white inset, 5px 5px 6px rgb(0 0 0 / 10%) inset;
}

.pillBadges div:nth-child(2) {
  margin: 0px 10px 0px 5px;
}

.nameAndStatus {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1201px) {
  .prayerContainer {
    margin: 0 20px 40px;
  }
}

@media only screen and (max-width: 1025px) {
  .prayerContainer {
    margin: 0 20px 40px;
  }
}

@media only screen and (max-width: 768px) {
  .letterIcon,
  .letterIcon::after {
    width: 40px;
    height: 40px;
    font-size: 1.8em;
  }
  .prayerContainer {
    padding: 15px;
    margin: 0px 0px 40px 0px;
  }
  .iconNameDate {
    align-items: flex-start;
  }
  .prayerText {
    margin: 0;
  }
  .buttonBank {
    border-top: 1px solid #dddddd;
    padding-top: 12px;
  }
}
