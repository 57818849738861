.buttonBank {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
}

.icon {
  margin-left: 20px;
  font-size: 1.3em;
  color: #444;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.icon:hover {
  color: #1d97b1;
}

.iconActive {
  color: #1d97b1;
}

.iconActive:hover {
  color: #444
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
  margin-left: 17px;
}

.icon[type="checkbox"] {
  display: none;
}

/* Hide the browser's default checkbox */
.buttonBank input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border: 2px solid #444;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #1d97b1;
  border-color: #1d97b1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 3px;
  top: 0px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

@media only screen and (max-width: 768px){
  .buttonBank {
    border-top: 1px solid #dddddd;
    padding-top: 12px;
  }
}