.container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.animationWrapper {
  position: relative;
  width: 50px;
  height: 50px;
  perspective: 60px;
  transform: scale(0.5);
}

.brick {
  width: 22px;
  height: 22px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: #1d97b1;
}

.brick1,
.brick2,
.brick3,
.brick4 {
  animation-timing-function: cubic-bezier(0.895, 0.03, 0.285, 0.22);
  animation-duration: 4s;
  animation-iteration-count: infinite;
  border-radius: 5px;
  transform-origin: center;
}

.brick1 {
  left: 0px;
  top: 0px;
  animation-name: brick1;
}

.brick2 {
  left: 30px;
  top: 0px;
  animation-name: brick2;
}

.brick3 {
  left: 30px;
  top: 30px;
  animation-name: brick3;
}
.brick4 {
  left: 0px;
  top: 30px;
  animation-name: brick4;
}

@keyframes brick1 {
  0% {
    left: 0px;
    top: 0px;
    transform: rotateY(180deg) rotateX(180deg);
  }
  25% {
    left: 30px;
    top: 0px;
    transform: rotateY(360deg) rotateX(180deg);
  }
  50% {
    left: 30px;
    top: 30px;
    transform: rotateY(360deg) rotateX(360deg);
  }
  75% {
    left: 0px;
    top: 30px;
    transform: rotateY(180deg) rotateX(360deg);
  }
  100% {
    left: 0px;
    top: 0px;
    transform: rotateY(180deg) rotateX(180deg);
  }
}

@keyframes brick2 {
  0% {
    left: 30px;
    top: 0px;
    transform: rotateY(360deg) rotateX(180deg);
  }
  25% {
    left: 30px;
    top: 30px;
    transform: rotateY(360deg) rotateX(360deg);
  }
  50% {
    left: 0px;
    top: 30px;
    transform: rotateY(180deg) rotateX(360deg);
  }
  75% {
    left: 0px;
    top: 0px;
    transform: rotateY(180deg) rotateX(180deg);
  }
  100% {
    left: 30px;
    top: 0px;
    transform: rotateY(360deg) rotateX(180deg);
  }
}

@keyframes brick3 {
  0% {
    left: 30px;
    top: 30px;
    transform: rotateY(180deg) rotateX(180deg);
  }
  25% {
    left: 0px;
    top: 30px;
    transform: rotateY(360deg) rotateX(180deg);
  }
  50% {
    left: 0px;
    top: 0px;
    transform: rotateY(360deg) rotateX(360deg);
  }
  75% {
    left: 30px;
    top: 0px;
    transform: rotateY(180deg) rotateX(360deg);
  }
  100% {
    left: 30px;
    top: 30px;
    transform: rotateY(180deg) rotateX(180deg);
  }
}

@keyframes brick4 {
  0% {
    left: 0px;
    top: 30px;
    transform: rotateY(360deg) rotateX(180deg);
  }
  25% {
    left: 0px;
    top: 0px;
    transform: rotateY(360deg) rotateX(360deg);
  }
  50% {
    left: 30px;
    top: 0px;
    transform: rotateY(180deg) rotateX(360deg);
  }
  75% {
    left: 30px;
    top: 30px;
    transform: rotateY(180deg) rotateX(180deg);
  }
  100% {
    left: 0px;
    top: 30px;
    transform: rotateY(360deg) rotateX(180deg);
  }
}
