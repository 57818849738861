.userBar {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
  z-index: 100;
}

.profilePic {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
  overflow: hidden;
  background: rgb(224, 224, 224);
}

.signOutText {
  display: inline-block;
  text-decoration: underline;
  margin-right: 25px;
}

@media only screen and (max-width: 1025px){
  .userBar {
    top: 6px;
  }
}