.grid {
  display: grid;
  height: 100%;
}

.buttonBank {
  display: flex;
  justify-content: flex-end;
  margin: 0px 20px 20px 20px;
  padding-top: 20px;
  position: relative;
}

.buttonBank button {
  margin-left: 15px;
}

.prayerWallWrapper {
  max-width: 1200px;
  position: relative;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .buttonBank {
    margin: 0px 10px 10px 10px;
  }
}
