.grid {
    display: grid;
    height: 100%;
}

.buttonBank {
    display: flex;
    align-items: left;
    justify-content: flex-end;
    padding-right: 15px;
}

.buttonBank button {
    margin-left: 15px;
    min-height: 0px;
    height: 30px;
    position: relative;
    bottom: -15px;
}

.noResults {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: rgb(190, 190, 190);
}

@media only screen and (max-width: 1025px){
    .buttonBank button {
        bottom: -10px;
    }
    .buttonBank {
        height: 10px;
    }
}

@media only screen and (max-width: 768px){
    .buttonBank {
        height: 0px;
    }
}