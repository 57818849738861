.icon {
  font-size: 1.3em;
  transition: 0.2s ease-in-out;
}

.userContainer {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.iconNameDate {
  display: flex;
  align-items: center;
  line-height: 1.5em;
  flex: 1;
}
.letterIcon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2em;
  background: teal;
  margin-right: 10px;
  position: relative;
  background-size: cover;
}
.letterIcon:after {
  width: 50px;
  height: 50px;
  display: block;
  content: "";
  left: 0;
  position: absolute;
  border-radius: 50%;
  background-image: linear-gradient(
    -45deg,
    rgb(0 0 0 / 7%),
    rgb(255 255 255 / 25%)
  );
}
.name {
  margin: 0;
}
.prayerText {
  display: block;
  margin: 10px 0;
  font-weight: 300;
}
.buttonBank {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}

.buttonBank .buttonWrapper {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownButton {
  display: flex;
  align-items: center;
  border: 2px solid #444;
  border-radius: 30px;
  padding: 3px 10px;
}

.dropdownButton select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  padding: 6px 20px 6px 6px;
  outline: none;
  border: none;
  background: none;
  border-radius: 20px;
  display: inline-block;
  font-weight: 600;
  position: relative;
  z-index: 2;
  color: #888;
}

.iconContainer,
.iconContainer:hover,
.iconContainer:disabled:hover {
  border-left: 2px solid #444;
  padding-left: 10px;
  color: #888;
  cursor: not-allowed;
}
.iconContainer[disabled="false"] {
  color: #444;
  cursor: pointer;
}
.iconContainer[disabled="false"]:hover,
.buttonWrapper.trashIcon:hover .icon {
  color: #1d97b1;
}

.carrotIcon {
  position: relative;
  left: -15px;
  z-index: 1;
  top: 1px;
}
