body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  display: flex;
  align-items: center;
  background: linear-gradient(-45deg, rgb(102 205 232), rgb(6 114 142));
  border: none;
  border-radius: 20px;
  padding: 0px 30px;
  min-height: 45px;
  overflow: hidden;
  color: white;
  box-shadow: 7px 7px 10px 0 rgba(0, 0, 0, 0.1),
    -5px -5px 7px 0 rgba(255, 255, 255, 0.5);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 3px 5px 10px rgba(0, 0, 0, 0.2);
}
button.disabled,
button.disabled:hover {
  background: #bcbcbc;
  cursor: default;
}
button:hover {
  cursor: pointer;
}

a {
  color: #1d809c;
  text-decoration: none;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 0.8em;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(-65deg, #1d97b1, #52d4ef);
  border-radius: 30px;
}

@media only screen and (max-width: 768px) {
  button {
    padding: 0 20px;
  }
}
