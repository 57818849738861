.grid {
    display: grid;
    height: 100%;
}

.noResults {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: rgb(190, 190, 190);
}