.wrapper {
  height: 100%;
  width: 100%;
}

.requestFormButton {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9;
}

.requestFormWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.requestForm {
  max-width: 90%;
  max-height: 90%;
  background: white;
  box-shadow: 6px 8px 30px rgba(0, 0, 0, 0.5);
  padding: 30px;
  border-radius: 30px;
  z-index: 3;
}

.backdrop {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.formButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0px 10px;
}

.formButtons button {
  margin-left: 15px;
}

.requestFormWrapper fieldset {
  display: flex;
  align-items: center;
  border: 1px solid rgb(194, 194, 194);
  padding: 20px;
  border-radius: 5px;
  margin: 20px 0px;
}

.requestFormWrapper fieldset label {
  display: flex;
  align-items: center;
}

.requestFormWrapper fieldset label input {
  margin: 0px 30px 0px 10px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid gray;
  display: inline-block;
}

.requestFormWrapper .stackyField label {
  display: flex;
  flex-direction: column;
}

.requestFormWrapper .stackyField textarea {
  margin-top: 10px;
  border: 1px solid rgb(194, 194, 194);
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .requestForm {
    padding: 15px;
    overflow: auto;
    border: 12px solid white;
  }

  .requestFormWrapper {
    position: fixed;
    padding: 15px;
    width: 100vw;
    height: calc(100vh - 145px);
    background: none;
  }

  .requestFormWrapper fieldset {
    flex-wrap: wrap;
    min-width: 100%;
    padding: 20px 10px;
  }

  .requestFormWrapper fieldset label input {
    margin-right: 10px;
    max-width: 140px;
  }

  .requestFormWrapper label {
    margin-bottom: 20px;
  }

  .formButtons {
    justify-content: flex-start;
  }
  .formButtons button {
    margin-left: 0;
    margin-right: 15px;
  }
  .backdrop {
    background: rgba(0, 0, 0, 0.2);
    min-height: 100vh;
  }
}
