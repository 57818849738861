.signInBackground {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e2e2e2;
  color: #444;
}

.signInWrapper {
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50px;
  padding: 50px;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.1),
    rgba(255, 255, 255, 0.58)
  );
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.09),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
}

.input {
  padding: 5px;
}

.errorMsg {
  border: 1px solid red;
  padding: 15px;
  border-radius: 6px;
  margin: 10px 0;
}

.successMsg {
  border: 1px solid rgb(72, 168, 35);
  padding: 15px;
  border-radius: 6px;
  margin: 10px 0;
}

.localH1 {
  margin: 0 0 30px;
  text-transform: uppercase;
  font-size: 1.8em;
  letter-spacing: 1px;
  border-bottom: 1px solid;
  display: block;
  width: 100%;
  text-align: center;
  line-height: 1.5em;
  margin-bottom: 22px;
}

.labelAndInput {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.labelAndInput label {
  text-transform: uppercase;
  font-size: 0.65em;
  font-weight: 600;
  color: #5d5d5d;
  margin-bottom: 8px;
}

.labelAndInput input[type="email"],
.labelAndInput input[type="password"],
.labelAndInput input[type="text"] {
  border-radius: 20px;
  border: none;
  padding: 15px;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid transparent;
}

.labelAndInput input[type="email"]:focus,
.labelAndInput input[type="password"]:focus,
.labelAndInput input[type="text"]:focus {
  border: 1px solid #84cae6;
}

.googleBtn {
  background: white;
  color: #444;
}
.googleBtn img {
  margin-right: 5px;
  width: 40px;
  height: 40px;
  margin-left: -10px;
}

.orChasm {
  margin-bottom: 15px;
  margin: 30px 0;
  display: flex;
  justify-content: center;
}

.orChasm:before,
.orChasm:after {
  content: "";
  flex: 1;
  border-top: 1px solid #888;
}

.orChasm p {
  padding: 0;
  margin: 0 10px;
  line-height: 0;
}