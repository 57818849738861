.container {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}
.sideBar {
  height: 100%;
  transition: 0.2s left ease-in-out;
}
.shadowBar {
  content: "";
  display: block;
  height: 90vh;
  width: 11px;
  box-shadow: 0px 0px 37px rgba(0, 0, 0, 0.8);
  position: absolute;
  left: 310px;
  top: 6vh;
  z-index: 1;
}
.menuToggleButton {
  width: 26px;
  height: 26px;
  margin: 0px;
  padding: 0px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  right: -33px;
  min-height: 0px;
  outline: none;
}
.innerWrapper {
  padding: 30px;
  height: 100%;
  flex: 1;
  width: 330px;
  background: #f2f2f2;
  position: relative;
  z-index: 2;
}

.mainContent {
  height: 100%;
  overflow: hidden;
  flex: 1;
  background-color: #f2f2f2;
  padding: 40px;
}

.logo {
  font-size: 1.3em;
  font-weight: 600;
  color: #676767;
  font-family: "Raleway";
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.fixedLogo {
  font-size: 1.3em;
  font-weight: 600;
  color: #676767;
  font-family: "Raleway";
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  top: 7px;
  left: 40px;
  position: absolute;
  display: none;
}

.localH1 {
  text-transform: uppercase;
  margin-bottom: 46px;
  font-size: 0.8em;
  color: #6a6a6a;
  letter-spacing: 1px;
  display: block;
  width: 100%;
  line-height: 1.5em;
}

.headerBackground {
  display: none;
  content: "";
}

@media only screen and (max-width: 1025px) {
  .sideBar {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
  }
  .fixedLogo {
    display: inline-block;
    font-size: 1.1em;
  }
}

@media only screen and (max-width: 768px) {
  .fixedLogo {
    font-size: 1em;
    z-index: 100;
  }
  .mainContent {
    padding: 20px 20px 40px 20px;
    overflow-y: auto;
    margin-top: 36px;
  }
  .headerBackground {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background: whitesmoke;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    height: 36px;
    z-index: 99;
  }
}

@media only screen and (max-width: 400px) {
  .fixedLogo {
    font-size: 0.7em;
  }
}
