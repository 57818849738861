.contentContainer {
  background: #f4f4f4;
  border-radius: 50px;
  box-shadow: 12px 12px 16px 0 rgb(0 0 0 / 15%),
    -8px -8px 12px 0 rgb(255 255 255 / 80%);
  height: calc(100% - 27px);
  border-top: 57px solid #f4f4f4;
  border-bottom: 57px solid #f4f4f4;
  border-right: 25px solid #f4f4f4;
  border-left: 25px solid #f4f4f4;
  padding-right: 17px;
  margin: 20px;
}

.containerHeader {
  font-size: 0.85em;
  text-transform: uppercase;
  margin: 0 0 10px;
  color: #6a6a6a;
  letter-spacing: 1px;
}

@media only screen and (max-width: 1201px) {
  .contentContainer {
    border-top: 27px solid #f4f4f4;
    border-bottom: 27px solid #f4f4f4;
    border-right: 22px solid #f4f4f4;
    border-left: 22px solid #f4f4f4;
    padding-top: 12px;
  }
}

@media only screen and (max-width: 1025px) {
  .containerHeader {
    padding-top: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .contentContainer {
    /* the !important's below is to override passed props */
    height: auto !important;
    overflow: visible !important;
    padding: 12px 0;
    margin: 20px 0px 20px 0px;
    box-shadow: none;
    border: none;
    background: none;
  }
}
