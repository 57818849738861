.navWrap {
  display: flex;
  flex-direction: column;
}

.mainNav a {
  display: block;
  margin: 20px 0;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #1d97b1;
  transition: 0.2s ease-in-out;
  position: relative;
}

.mainNav a.activeLink:after {
  content: "";
  display: block;
  border-right: 8px solid #1d97b1;
  border-top: 8px solid #1d97b1;
  border-left: 8px solid transparent;
  border-bottom: 8px solid transparent;
  position: absolute;
  width: 0px;
  height: 0px;
  transform: rotate(45deg);
  left: -40px;
  top: 1px;
}

.mainNav a:hover {
  color: #52d4ef;
}
